import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Hero from "../../components/Hero/Hero"
import TwoColumnGeneric from "../../components/Content/TwoColumnGeneric"
import SEO, { seoPropsMapping } from "../../components/seo"
import MarketoForm from "../../components/MarketoForm"
import { withPreview } from "gatsby-source-prismic"

const ReferBusiness = ({ data }) => {
  const section = data.prismicReferBusinessPage.data

  const seoProps = seoPropsMapping(section)

  const contentNodes = section.body.map((section, i) => {
    switch (section.slice_type) {
      case "2-column-generic":
        return (
          <div key={section.primary.section_id}>
            <TwoColumnGeneric key={`section-${section.primary.section_id}`} data={section}>
              <div
                className="uk-grid uk-grid-medium uk-grid-match uk-child-width-1-2@m"
                data-uk-grid
              >
                <div>
                  <div dangerouslySetInnerHTML={{ __html: section.primary.column_one.html }} />
                </div>
                <div
                  className={`uk-margin-medium ${section.slice_label &&
                    section.slice_label.includes("text-right") &&
                    "uk-flex-first"}`}
                >
                  <div className="uk-box-shadow-medium uk-padding-small uk-border-rounded">
                    <MarketoForm formId="1608" domainAddr="//app-aba.marketo.com" />
                  </div>
                </div>
              </div>
            </TwoColumnGeneric>
          </div>
        )
      case "embed_code":
        return (
          <div key={section.primary.section_id}>
            <div dangerouslySetInnerHTML={{ __html: section.primary.embed_code }}></div>
          </div>
        )
    }
  })

  return (
    <Layout>
      <SEO {...seoProps} />
      <Hero data={section} />
      {contentNodes}
    </Layout>
  )
}

export default withPreview(ReferBusiness)

export const referBusinessQuery = graphql`
  {
    prismicReferBusinessPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        hero_title {
          html
        }
        hero_text_alignment
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        body {
          ... on PrismicReferBusinessPageBody2ColumnGeneric {
            id
            slice_label
            slice_type
            primary {
              section_id
              column_one {
                html
              }
            }
          }
          ... on PrismicReferBusinessPageBodyEmbedCode {
            id
            slice_label
            slice_type
            primary {
              section_id
              section_name
              embed_code
            }
          }
        }
      }
    }
  }
`
